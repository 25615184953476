<template>
    <nav class="navbar px-4 my-1">
        <div class="container-fluid">
            <div class="navbar-brand">
                <img class="navbar-image" :alt="utils.APP_NAME" src="../media/img/logo.png" @click="activateFakeCall" />
            </div>

            <div v-if="utils.useDebug || isFakeCallActivated"
                @click="utils.redirectPath('/appointments', { objStr: utils.base64UrlEncode(JSON.stringify({ agendaId: 1, patientName: 'Paciente Anônimo Silva', patientCpf: '99999999999', patientNpf: 1, numConta: 1, patientAge: 33, isFake: 1 })) })">
                Acessar consulta FAKE
            </div>

            <div class="dropdown">
                <button class="btn no-border avatar dropdown-toggle" type="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <img :alt="'Foto do Usuário'" src="../media/img/default_avatar.jpg" />
                </button>
                <ul class="dropdown-menu dropdown-menu-end no-border">
                    <li>
                        <h6 class="dropdown-header">APAS Saúde - Bauru</h6>
                    </li>
                    <li v-if="!isAdmin"><span class="dropdown-item" @click="utils.redirectPath('/agenda')">
                            Agenda
                            <i class="bi bi-calendar-week"></i>
                        </span></li>
                    <li v-if="!isAdmin"><span class="dropdown-item"
                            @click="utils.redirectPath('/medical-records-list')">
                            Histórico
                            <i class="bi bi-clock-history"></i>
                        </span></li>
                    <li v-if="isAdmin"><span class="dropdown-item" @click="utils.redirectPath('/home')">
                            Ínicio
                            <i class="bi bi-house"></i>
                        </span></li>
                    <li v-if="isAdmin && permissoes.Contatos"><span class="dropdown-item"
                            @click="utils.redirectPath('/contacts')">
                            Contatos
                            <i class="bi bi-person"></i>
                        </span></li>
                    <li v-if="isAdmin && permissoes.Notificacoes"><span class="dropdown-item"
                            @click="utils.redirectPath('/notifications')">
                            Notificações
                            <i class="bi bi-bell"></i>
                        </span></li>
                    <li v-if="isAdmin && permissoes.Banners"><span class="dropdown-item"
                            @click="utils.redirectPath('/banners')">
                            Banners
                            <i class="bi bi-card-image"></i>
                        </span></li>
                    <li v-if="isAdmin && permissoes.Documentos"><span class="dropdown-item"
                            @click="utils.redirectPath('/documents')">
                            Documentos
                            <i class="bi bi-file-medical"></i>
                        </span></li>
                    <li v-if="isAdmin && permissoes.Noticias"><span class="dropdown-item"
                            @click="utils.redirectPath('/news')">
                            Notícias
                            <i class="bi bi-newspaper"></i>
                        </span></li>
                    <li v-if="isAdmin && permissoes.Informacoes"><span class="dropdown-item"
                            @click="utils.redirectPath('/info')">
                            Informações
                            <i class="bi bi-info-circle"></i>
                        </span></li>
                    <li v-if="isAdmin && permissoes.Configuracoes"><span class="dropdown-item"
                            @click="utils.redirectPath('/config')">
                            Configurações
                            <i class="bi bi-gear"></i>
                        </span></li>
                    <!--                    <li v-if="isAdmin"><span class="dropdown-item" @click="utils.redirectPath('/accrediteds-upload')">-->
                    <!--                        Upload de Credenciados (CSV)-->
                    <!--                        <i class="bi bi-database-add"></i>-->
                    <!--                    </span></li>-->
                    <li><span class="dropdown-item exit-button" @click="handleLogout">
                            Sair
                            <i class="bi bi-x"></i>
                        </span></li>
                </ul>
            </div>
        </div>
    </nav>

</template>

<script>
import utils from '@/helpers/utils';
import store from "@/store";

export default {
    name: 'UserBar',
    computed: {
        utils() {
            return utils;
        },
        store() {
            return store
        },
        isAdmin() {
            return !utils.isEmpty(JSON.parse(store.state.userData).isSysAdm);
        }
    },
    data() {
        return {
            integracaoMemed: false,
            isScriptLoaded: false,
            countClicksLogo: 0,
            isFakeCallActivated: false,
            permissoes: utils.isEmpty(JSON.parse(store.state.userData).Permissoes) ? [] : JSON.parse(store.state.userData).Permissoes.Acessos
        }
    },
    mounted() {
        this.getIntegracoes();
        this.countClicksLogo = 0;
        this.isFakeCallActivated = false;
    },
    methods: {
        async handleLogout() {
            await store.dispatch('setIsLoggedOff');
            utils.removeAppCookies();
            utils.redirectPath('/login');
        },
        async activateFakeCall() {
            this.countClicksLogo++;
            if (this.countClicksLogo >= 20) {
                // this.isFakeCallActivated = true;
            }
        },
        async getIntegracoes() {
            if (utils.isEmpty(JSON.parse(store.state.userData).isSysAdm)) {

                let userToken = await utils.callAPIMedico('post', '/memed-token', {
                    'mps': JSON.parse(store.state.userData).Mps,
                });
                if (utils.getErrorAPI(userToken) != null) {
                    await utils.presentToast(
                        utils.getErrorAPI(userToken),
                        'error'
                    );
                }

                let token = userToken.dados;

                if (!utils.isEmpty(token)) {
                    let response = await utils.callAPI('get', '/info/integracoes');
                    if (utils.getErrorAPI(response) != null) {
                        await utils.presentToast(
                            utils.getErrorAPI(response),
                            'error'
                        );
                    }

                    if (!utils.isEmpty(response.dados.memed)) {
                        let integracaoMemed = response.dados.memed;
                        if (integracaoMemed.integracao == 'sim') {

                            this.integracaoMemed = true;

                            let script = document.createElement("script");
                            script.src = "https://integrations.memed.com.br/modulos/plataforma.sinapse-prescricao/build/sinapse-prescricao.min.js";
                            script.dataset.token = token;

                            document.body.appendChild(script);
                        }
                    }
                }

            }
        }
    },
}
</script>

<style scoped>
img.navbar-image {
    height: 2rem;
}

.avatar img {
    height: 2rem;
    width: 2rem;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.dropdown-menu {
    background-color: #f1f1f1;
    box-shadow: 0 0 5px 2px #3331;
    padding-bottom: 0;
    overflow: hidden;
}

.dropdown-menu h6 {
    font-size: 0.75rem;
    font-weight: 600;
    padding-top: 0;
}

.dropdown-menu .dropdown-item {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.dropdown-menu .dropdown-item:hover {
    background-color: var(--bs-primary);
}

.dropdown-menu .dropdown-item.exit-button:hover {
    background-color: var(--bs-dropdown-link-hover-bg)
}
</style>
