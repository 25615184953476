<template>
    <div v-if="$store.state.isProgressBarVisible" class="overlay-container">
        <div class="center-middle spinner-border loading" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>

    <div v-if="$store.state.isLoggedIn" class="sys-header">
        <UserBar />
    </div>

    <div class="site-container mt-1 mx-4" v-if="$store.state.isLoggedIn && !isInCall">

        <aside class="lateral-menu-container" v-if="utils.isEmpty(JSON.parse($store.state.userData).isSysAdm)">
            <div class="lateral-menu-item" :class="isInAgenda" @click="utils.redirectPath('/agenda')">
                <i class="bi bi-calendar-week"></i>
            </div>
            <div class="lateral-menu-item" :class="isInRecords" @click="utils.redirectPath('/medical-records-list')">
                <i class="bi bi-clock-history"></i>
            </div>
        </aside>

        <aside class="lateral-menu-container" v-else>
            <div class="lateral-menu-item" :class="isInHome" @click="utils.redirectPath('/home')">
                <i class="bi bi-house"></i>
            </div>
            <div class="lateral-menu-item" v-if="JSON.parse($store.state.userData).Permissoes.Acessos.Contatos"
                :class="isInContacts" @click="utils.redirectPath('/contacts')">
                <i class="bi bi-person"></i>
            </div>
            <div class="lateral-menu-item" v-if="JSON.parse($store.state.userData).Permissoes.Acessos.Notificacoes"
                :class="isInNotifications" @click="utils.redirectPath('/notifications')">
                <i class="bi bi-bell"></i>
            </div>
            <div class="lateral-menu-item" v-if="JSON.parse($store.state.userData).Permissoes.Acessos.Banners"
                :class="isInBanners" @click="utils.redirectPath('/banners')">
                <i class="bi bi-card-image"></i>
            </div>
            <div class="lateral-menu-item" v-if="JSON.parse($store.state.userData).Permissoes.Acessos.Documentos"
                :class="isInDocuments" @click="utils.redirectPath('/documents')">
                <i class="bi bi-file-medical"></i>
            </div>
            <div class="lateral-menu-item" v-if="JSON.parse($store.state.userData).Permissoes.Acessos.Noticias"
                :class="isInNews" @click="utils.redirectPath('/news')">
                <i class="bi bi-newspaper"></i>
            </div>
            <div class="lateral-menu-item" v-if="JSON.parse($store.state.userData).Permissoes.Acessos.Informacoes"
                :class="isInInfo" @click="utils.redirectPath('/info')">
                <i class="bi bi-info-circle"></i>
            </div>
            <div class="lateral-menu-item" v-if="JSON.parse($store.state.userData).Permissoes.Acessos.Configuracoes"
                :class="isInConfigs" @click="utils.redirectPath('/config')">
                <i class="bi bi-gear"></i>
            </div>
            <!--            <div class="lateral-menu-item" :class="isInAccrediteds" @click="utils.redirectPath('/accrediteds-upload')">-->
            <!--                <i class="bi bi-database-add"></i>-->
            <!--            </div>-->
        </aside>

        <div class="pages-container">
            <div class="pages-container-inner">
                <router-view class="router-view" v-slot="{ Component }">
                    <component :is="Component" />
                </router-view>
            </div>
        </div>
    </div>

    <router-view class="router-view" v-if="!$store.state.isLoggedIn" v-slot="{ Component }">
        <component :is="Component" />
    </router-view>
</template>

<script setup>
import UserBar from './components/UserBar.vue';
import { useRouter } from "vue-router";
import utils from "@/helpers/utils";

const router = useRouter();
let isInAgenda = '',
    isInRecords = '',
    isInHome = '',
    isInContacts = '',
    isInNotifications = '',
    isInBanners = '',
    isInDocuments = '',
    isInNews = '',
    isInAccrediteds = '',
    isInInfo = '',
    isInConfigs = '',
    isInCall = false;

const setRouteSelected = (routeName) => {
    utils.l(routeName);
    utils.l(isInAccrediteds);

    switch (routeName) {
        case 'agenda':
            isInAgenda = 'selected';
            break;
        case 'medicalRecord':
        case 'medicalRecordsList':
            isInRecords = 'selected';
            break;
        case 'homePage':
            isInHome = 'selected';
            break;
        case 'contacts':
        case 'contactsAdd':
        case 'contactsVisualize':
        case 'contactsUpdate':
            isInContacts = 'selected';
            break;
        case 'notifications':
        case 'notificationsAdd':
        case 'notificationsVisualize':
            isInNotifications = 'selected';
            break;
        case 'banners':
        case 'bannersAdd':
        case 'bannersVisualize':
            isInBanners = 'selected';
            break;
        case 'documents':
        case 'documentsAdd':
        case 'documentsVisualize':
            isInDocuments = 'selected';
            break;
        case 'news':
        case 'newsAdd':
        case 'newsUpdate':
        case 'newsVisualize':
            isInNews = 'selected';
            break;
        case 'accreditedsUpload':
            isInAccrediteds = 'selected';
            break;
        case 'info':
        case 'callsInfo':
        case 'iaInfo':
        case 'statitistics':
            isInInfo = 'selected';
            break;
        case 'config':
        case 'integration':
        case 'schendulesConfig':
        case 'iaConfig':
        case 'authConfig':
        case 'demonstrativeConfig':
        case 'profissional':
        case 'userUpdate':
        case 'reception':
        case 'appConfig':
        case 'usersGroup':
            isInConfigs = 'selected';
            break;
        case 'call':
            isInCall = true;
    }
}

setRouteSelected(router.currentRoute.value.name);

router.beforeEach((to) => {
    isInAgenda = '';
    isInRecords = '';
    isInHome = '';
    isInContacts = '';
    isInNotifications = '';
    isInBanners = '';
    isInDocuments = '';
    isInNews = '';
    isInAccrediteds = '';
    isInInfo = '',
        isInConfigs = '',
        isInCall = false;

    setRouteSelected(to.name);
})
</script>

<style>
* {
    font-family: sans-serif;
}

.site-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.site-container .lateral-menu-container .lateral-menu-item {
    color: #999;
    padding: 1rem;
    font-size: 1.5rem;
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
    transition: 0.3s;
    cursor: pointer;
    position: relative;
}

.site-container .lateral-menu-container .lateral-menu-item.selected::after {
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    background-color: #efefef;
}

.site-container .lateral-menu-container .lateral-menu-item:hover {
    color: #777;
    background: #efefef66;
}

.site-container .lateral-menu-container .lateral-menu-item.selected,
.site-container .pages-container {
    background: #efefef;
    box-shadow: 5px 5px 5px 2px #3331
}

.site-container .lateral-menu-container .lateral-menu-item.selected {
    color: #555;
}

.site-container .pages-container {
    flex: 1;
    border-radius: 1.5rem;
    box-shadow: 5px 5px 5px 2px #3331;
    z-index: 10;

    height: calc(100vh - 90px);
    max-height: calc(100vh - 90px);

    padding: 2rem 2rem;
    overflow: hidden;
}

.site-container .pages-container .pages-container-inner {
    height: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.site-container .pages-container .pages-container-inner::-webkit-scrollbar {
    display: none;
}
</style>
